import React, { useState, useEffect } from "react";
import "../index.css";

const FirstBiteGallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch the JSON data
    fetch("/firstbitegallery.json")
      .then((response) => response.json())
      .then((data) => setImages(data))
      .catch((error) => console.error("Error fetching images:", error));
  }, []);

  return (
    <>
      <h1 className="text-center mt-5 mb-3">Kain Sining - First Bite</h1>
      <p className="text-center p-5 fs-4 mb-5">
        At the start of 2024, the boys from{" "}
        <a href="https://www.facebook.com/kainsining" target="_blank">
          Kain Sining
        </a>{" "}
        kick started "First Bite", an event which featured various Filipino
        artists/musicians of different genres and gave the crowd a taste of
        "lutong pinoy" music. Amongst the artists and talented musicians who
        played that night were Same Street, Jayvee & Sarah Almy (with their
        bands) and YOKIV, who released his EP album early this year. Be sure to
        checkout his epic rap/ hiphop songs{" "}
        <a
          href="https://open.spotify.com/artist/1U72Tctb4sE1nofwetl3uE?si=du97n-e7Qs-_8-_xet2YQA"
          target="_blank"
        >
          here.
        </a>{" "}
        Fuego and Joint Project (I got the privilege to share the stage with
        these elite artists, salamat mga ka-sago!) wrapped up the night with
        their awesome performances . Check out these photos from the event.
      </p>
      <div className="gallery">
        {images.map((item, index) => {
          return (
            <div className="pics" key={index}>
              <img src={item.image} style={{ width: "100%" }} />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FirstBiteGallery;
