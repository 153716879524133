import React, { useState, useEffect } from "react";
import "../index.css";
import { Helmet, HelmetProvider } from "react-helmet-async";
const RenierKatGallery = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch the JSON data
    fetch("/renierkatgallery.json")
      .then((response) => response.json())
      .then((data) => setImages(data))
      .catch((error) => console.error("Error fetching images:", error));
  }, []);

   return (
    <HelmetProvider>

    <>
      <Helmet>
        <meta property="og:url" content="https://glenguarinphoto.com/renierkatgallery" />
        <meta property="og:description" content="Renier and Kat" />
        <meta property="og:image" content="https://live.staticflickr.com/65535/53774453288_fb5ffec989_b.jpg" />
      </Helmet>
      <h1 className="text-center mt-5 mb-3">Renier and Kat</h1>
      <p className="text-center p-5 fs-4 mb-5">
      It's just great to be back at shooting weddings again. Settlers Country Manor is such a beautiful historic venue and it's the perfect place to house Renier and Kat's wedding. With Auckland's extra unpredictable weather, it's best to have options between having the ceremony indoors or setting up a garden wedding in a snap of a finger. Thanks to the couple's fantastic planning, the event came out with warmth, style & emotion and we got to shoot the couple outdoors as well. Congratulations to your lovely wedding Renier and Katkat!
      </p>
      <div className="gallery">
        {images.map((item, index) => {
          return (
            <div className="pics" key={index}>
              <img src={item.image} style={{ width: "100%" }} />
            </div>
          );
        })}
      </div>
    </>
    </HelmetProvider>
  );
  
};

export default RenierKatGallery;
